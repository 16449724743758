<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-6 m-auto">
                    <h1 class="text-center">Login</h1>
                    <form @submit="enviar">
                        <input type="password" v-model='passWord' placeholder="Senha" class="form-control mx-auto mb-3" v-on:keypress="errCPF = null" required :class=" {'is-invalid': errPassWord}">
                        <button class="btn btn-success d-block m-auto">ENVIAR</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: 'Login',
    data (){
        return{
            passWord: '',
            errPassWord: null
        }
    },
    computed:{
    },
    methods:{
        enviar(e){
            e.preventDefault()

            const entrar = this.passWord != '123' ? this.errPassWord = true : this.errPassWord = false

            if(!entrar){
                this.$store.commit('UPDATE_LOGIN_DASH', true)
                this.$router.push('/dashboard')
            }

        }
    }
}
</script>

<style lang="scss" scoped>

</style>
