<template>
  <div>
    <b-alert show variant="danger" v-if="alert.message">
      {{alert.message}}
    </b-alert>
    
    <b-list-group class="listGroup" vertical>
      <!-- Infos -->
      <b-list-group-item>
        <div class="row">
          <div class="col col-12 col-sm-auto mb-2 mb-sm-0">
            <p>
              <strong>Convidados</strong>
            </p>
          </div>

          <div class="col col-12 col-sm-auto mb-2 mb-sm-0">
            <p>
              <strong>Confirmados:</strong> {{numberOfConfirmed}}
            </p>
          </div>
          <div class="col col-auto">
            <p>
              <strong>Aguardando:</strong> {{numberOfWaiting}}
            </p>
          </div>
        </div>
      </b-list-group-item>

      <!-- Head -->
      <b-list-group-item class="d-none d-md-block">
        <div class="row">
          <div class="col col-4 col-lg-4">
            <p>
              <strong>Nome</strong>
            </p>
          </div>
          <div class="col col-4 col-md-5">
            <p>
              <strong>E-mail</strong>
            </p>
          </div>
          <div class="col col-auto">
            <p>
              <strong>Ação</strong>
            </p>
          </div>
        </div>
      </b-list-group-item>

      <!-- Spinner -->
      <div class="d-flex align-items-center justify-content-center text-center mt-3" v-if="loading">
        <b-spinner variant="success" label="Carregando...">
        </b-spinner>
      </div>

      <!-- Guests -->
      <b-list-group-item class="listGroup__item" v-else-if="(typeof this.data !== 'string')" v-for="(guest, key) in this.data" :key="key">
        <div class="row flex-column flex-md-row">
          <div class="col col-12 col-lg-4">
            <p class="d-block d-md-none">
              <strong>Nome</strong>
            </p>

            <p>
              {{guest.name}}
            </p>
          </div>

          <div class="col col-12 col-md-5">
            <p class="d-block d-md-none">
              <strong>E-mail</strong>
            </p>

            <p>
              {{guest.email}}
            </p>
          </div>

          <div class="col col-auto">
            <p class="d-block d-md-none mb-2">
              <strong>Ações</strong>
            </p>

            <div class="row">
              <style>
                @media(min-width: 768px){
                  .colunabotao1{
                    min-width: 148px;
                  }
                }
              </style>
              <div class="colunabotao1 col col-12 col-sm-auto mb-2 mb-sm-0">
                <c-action-button @actionButtonError="actionButtonError" :propsData="guest" propsText="Confirmar" propsType="confirm" :propsKey="key" :propsId="guest.id" :propsConfirm="guest.presence" />
              </div>
              
              <div class="col col-12 col-sm-auto">
                <c-action-button @actionButtonError="actionButtonError" :propsData="guest" propsText="Deletar" propsType="delete" :propsKey="key" :propsId="guest.id" />
              </div>
            </div>
          </div>
        </div>
      </b-list-group-item>

      <b-list-group-item v-else-if="(typeof this.data === 'string')">
        <p class="text-center">
          <strong>{{ data }}</strong>
        </p>
      </b-list-group-item>
    </b-list-group>
  </div>

</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import cActionButton from './c-action-button.vue';

export default {
  components: { cActionButton },
  name: 'listComponent',

  computed: {
    ...mapState(['guests', 'search']),
  },

  data(){
    return{
      loading: true,
      data: 0,
      numberOfConfirmed: 0,
      numberOfWaiting: 0,
      sortBy: 'name',
      fields: ['name', 'email', 'action'],
      alert: {
        message: null,
      },
    }
  },

  watch: {
    search: {
      handler(){
        this.getValues()
      },
      deep: true,
    }
  },

  mounted(){
   setTimeout(async () => {
    await this.searchInApi()
   }, 1000);
  },

  methods: {
    async searchInApi(){
      try {
        await axios.get("https://apirsvp.cantacom.com.br/portaria/user/list")
          .then((response) => {
            this.$store.commit("SET_GUESTS", response.data)
            this.$store.commit("SET_SEARCH", response.data)

            this.getValues()
          })
          .catch((error) => {
            console.log(error)

            this.alert.message = "Ops, erro no sistema! Recarregue a página e tenve novamente."
          })
      } catch (error) {
        console.log(error)

        this.alert.message = "Ops, erro no sistema! Recarregue a página e tenve novamente."

        setTimeout(() => {
          this.alert.message = null;
        }, 5000);
      }
    },

    getValues(){
      this.numberOfConfirmed = 0
      this.numberOfWaiting = 0
      this.data = 0;
      this.loading = true

      setTimeout(() => {
        this.data = JSON.parse(JSON.stringify(this.search))
        
        // if(typeof this.data !== 'string'){
        //   this.data.sort(function(a, b) {
        //     return a.name.localeCompare(b.name);
        //   });
        // }

        this.loading = false
      }, 1000);

      this.guests.filter((e) => {
        return e.presence == 'confirmado' ? this.numberOfConfirmed++ : this.numberOfWaiting++;
      })
    },

    actionButtonError(){
      this.alert.message = "Ops, erro ao executar a ação! Recarregue a página e tenve novamente."

      setTimeout(() => {
        this.alert.message = null;
      }, 3000);
    }
  },
}
</script>

<style lang="scss" scoped>
@media(min-width: 991px){
  .listGroup{
    &__item{
      p{
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
</style>