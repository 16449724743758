<template>
  <b-modal id="modalEditGuest" no-close-on-backdrop no-close-on-esc hide-header hide-footer>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-between align-items-center">
                <p class="modalTitle mb-0">Editar convidado</p>
                <i class="fas fa-times iconClose" @click.prevent="closeModal"></i>
            </div>
            <form @submit.prevent="editGuest" class="formNewGuest mt-4">
                <b-alert :variant="someError.variant" show v-if="someError.status">
                    <i class="fas fa-info-circle mr-3"></i>{{someError.msg}}
                </b-alert>
                <div class="d-flex flex-column align-items-center">
                    <div class="form-group w-100">
                        <label for="nameGuest">Nome</label>
                        <input type="text" class="form-control" id="nameGuest" @change="someEdited" v-model="userEditing.name" required :readonly="isEditing">
                    </div>
                    <div class="form-group w-100">
                        <label for="emailGuest">Email</label>
                        <input type="text" class="form-control" id="emailGuest" @change="someEdited" v-model="userEditing.email" required :readonly="isEditing">
                    </div>
                    <div class="form-group w-100">
                      <label for="emailGuest">Empresa</label>
                      <input type="text" class="form-control" id="companyGuest" @change="someEdited" v-model="userEditing.company" :readonly="isEditing">
                    </div>
                    <div class="form-group w-100">
                        <label for="statusGuest">Status</label>
                        <select class="form-control w-100" id="selectStatus" @change="someEdited" v-model="userEditing.presence" :disabled="isEditing">
                            <option value="true">Eu vou</option>
                            <option value="false">Vou perder</option>
                            <option value="standby">Não respondi</option>
                        </select>
                    </div>
                </div>                           
                <div class="d-flex justify-content-end mt-3">
                    <button :disabled="isEditing" @click.prevent="closeModal" class="btn btn-secondary mr-3">Voltar</button>
                    <button :disabled="isEditing" class="btn btn-warning"><i class="fas fa-edit mr-2"></i> Editar</button>
                </div>
            </form>
        </div>
    </div>
  </b-modal>
</template>

<script>
import { api } from '@/services.js'
export default {
name: 'ModalEditingUser',
props:['userEditing','edited','userBackup','closeModalStatus'],
data() {
    return {
        user: {

        },
        isEditing: false,
        someError: {
            status: false,
            msg: '',
            variant:''
        },
        sent: false,
        someChange: false
    }
},
methods: {
    closeModal() {
        if(this.someChange && !this.sent) {
            this.$emit("update:closeModalStatus",!this.closeModalStatus)
        }
        this.someChange = false
        this.sent = false
        this.someError.status = false
        this.$bvModal.hide('modalEditGuest')
    },
    someEdited() {
        this.someChange = true
    },
    async editGuest() {
        this.isEditing = true
        var dataToSend = {
            name: this.userEditing.name,
            email: this.userEditing.email,
            company: this.userEditing.company,
            presence: this.userEditing.presence
        }
        try {
            await api.put(`/user/confirm-presence/${this.userEditing.token}`, dataToSend).then(res=> {
                if(res.data.code == 200) {
                        this.someError.status = true
                        this.someError.msg = 'Convidado editado com sucesso!'
                        this.someError.variant = 'success'
                        this.isEditing = false
                        this.$emit("update:edited",!this.edited)
                        this.sent = true
                }else {
                    this.someError.status = true
                    this.someError.msg = 'Algo de errado aconteceu, tente novamente mais tarde!'
                    this.someError.variant = 'danger'
                    this.isEditing = false
                }
            })
        } catch (error) {
            this.someError.status = true
            this.someError.msg = 'Algo de errado aconteceu, tente novamente mais tarde!'
            this.someError.variant = 'danger'
            this.isEditing = false
        }
        
        setTimeout(() => {
            this.isEditing = false
        }, 1000);
    },
}
}
</script>

<style lang="scss" scoped>
    .modalTitle {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .iconClose {
        cursor: pointer;
        font-size: 1.5rem;
        transition: .2s;
        &:hover {
            color: #FB2B67;
        }
    }

    .textModal {
        font-size: 1.25rem;
    }
</style>