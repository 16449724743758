<template>
  <div>

    <Table />

  </div>
</template>

<script>
import { mapState } from "vuex";
import Table from '@/components/admin/Table.vue'

export default {
    name: "Dashboard",
    components:{
        Table
    },
    computed:{
        ...mapState(["loginDash", "usersStore"]),
    },
    methods:{
        redrectLogin(){
            !this.loginDash ? this.$router.push("/admin") : null
        }
    },
    mounted(){
        this.redrectLogin()
    }

}
</script>

<style>

</style>