<template>
  <div class="page pt-md-5 pt-0">
    <div class="container pt-5">

      <h1 class="text-center mb-md-4 mt-md-5 mb-3">Página não encontrada :(</h1>

      <img src="@/assets/img/logo-festa.png" class="img-canta fixed-bottom pb-5 d-block mx-auto" alt="Logo  Canta">

    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  data() {
    return{
      // status: null
    }
  },
  computed: {
  },
  methods:{
  },
  created(){
    // this.$router.currentRoute.path
  }
}
</script>

<style lang="scss" scoped>

@import url('../assets/scss/geral.scss');

$regular: "FreightSans Pro";
$bold: "FreightSans Pro Bold";
$light-italico: "FreightSans Pro Light Italico";
$regular-italico: "FreightSans Pro Italico";
$bold-italico: "FreightSans Pro Bold Italico";

$cor1: #FB2B67;
$cor2: #FAFAFA;
$cor3: #707070;
$cor4: #01D93B;


h1{
  font-size: 2.4rem;
  font-family: $bold;
  color: $cor2;
  position: relative;
    top: 100px;
  @media(max-width: 767px){
    font-size: 2rem;
  }
  @media(max-height: 767px){
    font-size: 1.4rem;
    position: relative;
    top: 80px
  }
}

p{
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-family: $regular;
  color: $cor2;
   @media(max-width: 767px){
    font-size: 1.1rem;
    line-height: 1.5rem;
     position: relative;
    top: 80px
  }
   @media(max-height: 767px){
     font-size: .9rem;
     line-height: 1.2rem;
    position: relative;
    top: 0px
  }
}

.btn-enviar{
  background-color: $cor1;
  color: $cor2;
  font-family: $bold;
  font-size: 1.2rem;
  letter-spacing: .03rem;
  padding: 8px 15px;
  border-radius: 13px;
   @media(max-width: 767px){
     border-radius: 10px;
    font-size: 1rem;
    position: relative;
    top: 150px
  }
  @media(max-height: 767px){
    position: relative;
    top: 0px
  }
}

.img-canta{
  height: 300px;
  @media(max-width: 767px){
   height: 300px;
  }
}

</style>
