<template>
  <b-modal id="modalNewGuest" title="Adicionar novo convidado" no-close-on-backdrop no-close-on-esc hide-header hide-footer>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-between align-items-center">
                <p class="modalTitle mb-0">Adicionar novo convidado</p>
                <i class="fas fa-times iconClose" @click.prevent="closeModal"></i>
            </div>
            <form @submit.prevent="addGuest" class="formNewGuest mt-4">
                <b-alert :variant="someError.variant" show v-if="someError.status">
                    <i class="fas fa-info-circle mr-3"></i>{{someError.msg}}
                </b-alert>
                <div class="d-flex flex-column align-items-center">
                    <div class="form-group">
                        <label for="nameGuest">Nome</label>
                        <input type="text" class="form-control" id="nameGuest" v-model="form.name" required :readonly="isSending">
                    </div>
                    <div class="form-group">
                        <label for="emailGuest">Email</label>
                        <input type="text" class="form-control" id="emailGuest" v-model="form.email" required :readonly="isSending">
                    </div>
                    <div class="form-group">
                      <label for="emailGuest">Empresa</label>
                      <input type="text" class="form-control" id="companyGuest" v-model="form.company" :readonly="isSending">
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button :disabled="isSending" @click.prevent="closeModal" class="btn btn-danger mr-3">Cancelar</button>
                    <button :disabled="isSending" class="btn btn-primary">Cadastrar</button>
                </div>
            </form>
        </div>
    </div>
  </b-modal>
</template>

<script>
import { api } from '@/services.js'
export default {
name: 'ModalNewUser',
props:['added'],
data() {
    return {
        form: {
            name:'',
            email:'',
            company: '',
        },
        isSending: false,
        sent: false,
        someError: {
            status: false,
            msg: '',
            variant:''
        }
    }
},
methods: {
    closeModal() {
        this.$bvModal.hide('modalNewGuest')
        this.someError.status = false
    },
    async verifyExist() {
        try {
            return await api.get('/user/list').then(response => {
                if (response.data.length>0) {
                  console.log('Oi')
                    return response.data.filter(c=>c.email == this.form.email).length
                }else {
                    return 0;
                }          
            })
        } catch (error) {
            return 'error'
        }
    },
    async addGuest() {
        this.isSending = true
        this.someError.status = false
        this.form.name = this.form.name.trim()
        this.form.email = this.form.email.trim()
        this.form.company = this.form.company.trim()
        
        const userExist = await this.verifyExist()
        
        if(userExist==0) { // Cadastra convidado

            try {
                await api.post('/user/cadastro',this.form).then(res=> {
                    if(res.data.code == 201) {
                        this.someError.status = true
                        this.someError.msg = 'Convidado cadastrado com sucesso!'
                        this.someError.variant = 'success'
                        this.isSending = false
                        this.$emit("update:added",!this.added)
                        this.form.email = ''
                        this.form.name = ''
                        this.form.company = ''
                    }else {
                        this.someError.status = true
                        this.someError.msg = 'Algo de errado aconteceu, tente novamente mais tarde!'
                        this.someError.variant = 'danger'
                        this.isSending = false
                    }
                })
            } catch (error) {
                this.someError.status = true
                this.someError.msg = 'Algo de errado aconteceu, tente novamente mais tarde!'
                this.someError.variant = 'danger'
                this.isSending = false
                return
            }

        }else if(userExist>=1) { // Convidado já existe
            this.someError.status = true
            this.someError.msg = 'Este email já existe em outro convidado'
            this.someError.variant = 'warning'
            this.isSending = false
            return
        }else { // Erro na requisição
            this.someError.status = true
            this.someError.msg = 'Algo de errado aconteceu, tente novamente mais tarde!'
            this.someError.variant = 'danger'
            this.isSending = false
            return
        }
        
    }
}
}
</script>

<style lang="scss" scoped>
    .modalTitle {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .iconClose {
        cursor: pointer;
        font-size: 1.5rem;
        transition: .2s;
        &:hover {
            color: #FB2B67;
        }
    }
</style>