<template>
<div class="page pt-5">
  <Carregamento v-if=" presence == null" />
  <div v-else class="container pt-5">

    <div class="row">
      <div class="col">
        <h1 class="text-center title-home mb-md-4 mt-md-5 mb-3" style="font-family: 'FreightSans Pro Bold' !important; color: #FB2B67 !important;">
          E AÍ, {{user.name}}.
        </h1>
        <p class="text-center sub-title-home">
          A festona mais esperada do ano tá chegando e nós queremos muito que você venha se divertir <br class="d-none d-md-block"/>
          com a gente e que dê todos os abraços que não pôde dar nesses dois últimos anos. <br class="d-none d-md-block"/>
          Bora?
        </p>
        <div class="d-flex justify-content-center mt-4 pt-4">
          <div @click="responder(1)" class="box py-3 mr-4" :class="{activeVou: presence == 'true', disabled: btnVotar.disabled}">
            <img src="@/assets/img/rock.png" class="d-block mx-auto" alt="img">
            <p class="mb-0 mt-2 text-center">
              EU VOU!
            </p>
          </div>

          <div @click="responder(2)" class="box py-3" :class="{activePerder: presence == 'false', disabled: btnVotar.disabled}">
            <img src="@/assets/img/no.png" class="d-block mx-auto" alt="img">
            <p class="mb-0 mt-2 text-center">
              <span>VOU PERDER!</span>
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4 pt-4">
          <button @click="confirm()" :disabled="presence == null" class="btn-enviar border-0 d-block mx-auto mb-5" :class="{disabled: presence == null}">
            {{ btn.txt }}
          </button>
        </div>
        <div class="d-flex justify-content-center mt-4 pt-4">
          <img src="@/assets/img/logo-canta.png" class="img-canta  pb-5 d-block mx-auto" alt="Logo  Canta">
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { api } from '@/services.js'
import Carregamento from '@/components/Carregamento';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  data() {
    return{
      presence: "true",
      btn:{
        txt: "ENVIAR RESPOSTA",
      },
      btnVotar: {
        disabled: false
      }
    }
  },
  components: {
    Carregamento
  },
  computed: {
    ...mapState(['user'])
  },
  methods:{
    responder(param){

      if(!this.btnVotar.disabled){
        switch (param) {
          case 1:
            this.presence = "true"
            break;
          default:
            this.presence = "false"
            break;
        }
      }
      
    },
    confirm(){
      this.btn.txt = "ENVIANDO RESPOSTA.."
      this.btnVotar.disabled = true
      api.put(`/user/confirm-presence/${this.$route.params.token}`,
      {
        "presence": this.presence
      })
      .then(response => {
        this.btn.txt = "ENVIANDO ENVIADA"
        this.$store.commit('UPDATE_USER', response.data.data)
        // let presence =  response.data.data.presence
        this.$router.push(`/end/${this.$route.params.token}`)
      })
    },
      getUser(){
      api.get(`/user/store/${this.$route.params.token}`)
      .then(response => {
        // console.log(response.data.code)
        if(response.data.code == 200){

          this.$store.commit('UPDATE_USER', response.data.data)
          let presence =  response.data.data.presence
          this.presence =  presence
          if(presence != "standby"){
            this.$router.push(`/end/${this.$route.params.token}`)
          }

        } else if (response.data.code == 401) {

          this.$router.push('/notfound')

        } else {

          this.$router.push('/notfound')

        }
        
      })
    }
  },
  created(){
    //this.getUser()
    this.confirm()
  }
}
</script>

<style lang="scss" scoped>

@import url('../assets/scss/geral.scss');

$regular: "FreightSans Pro";
$bold: "FreightSans Pro Bold";
$light-italico: "FreightSans Pro Light Italico";
$regular-italico: "FreightSans Pro Italico";
$bold-italico: "FreightSans Pro Bold Italico";

$cor1: #FB2B67;
$cor2: #FAFAFA;
$cor3: #424242;
$cor4: #01D93B;

// .containerBtn {
//   margin-top: 30px;
//   @media(max-height: 767px){
//     margin-top: 100px !important;
//   }
// }

// .containerConfirmData {

//   label {
//     color: #fff;
//   }

//   @media(max-height: 767px){
    
//     padding-bottom: 50px;
//   }
// }

.title-home {
  font-size: 2.4rem;
  font-family: $bold !important;
  color: $cor1 !important;
  @media(max-width: 767px){
    font-size: 2rem;
    // position: relative;
    // top: 80px
  }
  @media(max-height: 767px){
    font-size: 1.8rem;
    // position: relative;
    // top: 0px
  }

}

.sub-title-home{
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-family: $regular;
  color: $cor2;
   @media(max-width: 767px){
    font-size: 1.1rem;
    line-height: 1.5rem;
    // position: relative;
    // top: 80px
  }
   @media(max-height: 767px){
     font-size: .9rem;
     line-height: 1.2rem;
    // position: relative;
    // top: 0px
  }
}

.w-fit{
  width: fit-content;
}
.box{
  cursor: pointer;
  background: white;
  width: 140px;
  border-radius: 15px;
  transition: .3s;
  img{
    height: 60px;
  }
  p{
    color:$cor3;
    font-family: $bold-italico;
    font-size: 1.4rem;
    span{
      font-size: 1.1rem;
    }
  }
  @media(max-height: 767px) {
    width: 130px;
    // position: relative;
    // top: -100px;
    img {
      height: 50px;
    }

    p {
      font-size: 1.3rem;
      span{
        font-size: 1rem;
      }
    }
    
  }
}
.box.active{
  background-color: $cor4;
}
.activeVou {
  background-color: $cor4;
}

.activePerder {
  background-color: #FC5F5F;
}
.box.disabled{
  opacity: .7;
  cursor: context-menu;
}

.btn-enviar{
  background-color: $cor1;
  color: $cor2;
  font-family: $bold;
  font-size: 1.4rem;
  letter-spacing: .03rem;
  padding: 10px 30px;
  border-radius: 10px;
  transition: .3s;
  @media(max-height: 767px){
    // position: relative;
    // top: -130px
  }
}
.btn-enviar.disabled{
  opacity: .7;
}
.img-canta{
  height: 160px;
  // position: relative;
  // top: 30px;
  @media(max-width: 767px){
   height: 140px;
  }
  // @media(max-height:767px) {
  //   top: -100px;
  // }
}

</style>
