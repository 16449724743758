<template>
  <div>
    <div class="container">
      <!-- HEADER -->
      <div class="row my-3 pt-4 box shadow rounded">
        <div class="col ">

          <div class="d-flex justify-content-between align-items-center mb-3">
           

            <div>
              <b-input-group>
              <b-form-input v-model="keyword" @keypress="currentPage = 1" class="border-0 border-bottom" placeholder="Nome, E-mail, Status"></b-form-input>
              <b-input-group-text :class="{disabled : !keyword, 'text-danger' : keyword, }" class="btn"  @click="keyword = ''"><strong>X</strong></b-input-group-text>
            </b-input-group>
            <small class="ml-2">Para status, usar: <b>true, false ou standby</b></small>
            </div>

            <div class="d-flex">
               <download-excel
                class="btn btn-success xls mr-3"
                :data="items"
                :fields="json_fields"
                worksheet="My Worksheet"
                type="json"
                name="CantaParty.xls"
                >
                Exportar xls
              </download-excel>
              <b-button size="sm" pill variant="warning" @click="atualizar" class="text-white mr-3">{{btnAtualizar}}</b-button>
              <b-button size="sm" pill variant="success" @click="addNovo" class="text-white">{{btnAddNovo}}</b-button>
            </div>

          </div>

            <b-pagination v-if="usersStore"
              pills
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              align="center"
              first-number
              last-number
            ></b-pagination>

        </div>
      </div>

      <!-- TABLE -->
      <div class="row my-3 pt-4 box shadow rounded">
        <div class="col">
        
          <transition v-if="!usersStore" name="fade"  mode="out-in">
            <div  class="text-center text-danger">
              <strong>{{errTxt}}</strong>
            </div>
          </transition>
          <!-- BOTÕES DE SELEÇÃO -->
          <div class="mb-3 d-flex justify-content-between">
            <b-button size="sm" pill :disabled="checkSelected" variant="success" @click="sendSelected">{{btnEnviar}}</b-button>
            <div>
              <b-button size="sm" pill variant="primary" class="mx-3" @click="selectAllRows">Selecionar Todos</b-button>
              <b-button size="sm" pill variant="danger" @click="clearSelected">Desmarcar Todos</b-button>
            </div>
          </div>
          <h1 class="mb-4" v-if="usersStore">Convidados: {{this.leads.length}}</h1>
          <h5 class="mb-3">Resumo geral: <b>Eu vou: {{reumeStatus.go}}</b> <i class="fas fa-cocktail text-info mx-2"></i> <b>Vou perder: {{reumeStatus.loss}}</b> <i class="text-info mx-2 fas fa-cocktail"></i> <b>Não respondi: {{reumeStatus.standby}}</b> </h5>

          <transition v-if="usersStore" name="slide-fade" mode="out-in">
            
            <!-- PROPS TABLE DE SELEÇÃO -->
            <!-- :select-mode="selectMode" ref="selectableTable" selectable @row-selected="onRowSelected"  -->
            <b-table id="my-table" :select-mode="selectMode" ref="selectableTable" selectable no-select-on-click @row-selected="onRowSelected" small :items="items" :fields="fields" :keyword="keyword" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :per-page="perPage" :current-page="currentPage" outlined >
              

              <template #cell(selected)="data">
                    <b-form-checkbox
                      class="checkbox"
                      @change="checked(data.index, data.rowSelected)"
                      v-model="data.rowSelected"
                    >
                    </b-form-checkbox>          
              </template>

              <template #cell(actions)="data">
                <div class="d-flex">
                  <i class="fas fa-edit actionIcon mr-3" @click.prevent="editGuest(data.item)"></i>  
                  <i class="fas fa-trash-alt actionIcon" @click.prevent="removeGuest(data.item)"></i>  
                </div>      
              </template>              
              

              <template #cell(presence)="data">
                <b-badge v-if="data.value == 'true'" class="bg-success">Confirmado</b-badge>
                <b-badge v-if="data.value == 'false'" class="bg-danger">Não irá</b-badge>
                <b-badge v-if="data.value == 'standby'" class="bg-warning">Aguardando</b-badge>
              </template>

              <template #cell(sentemail)="data">
                <b-badge v-if="data.value == 'false' || data.value == ''"></b-badge>
                <b-badge v-else class="bg-success">{{ new Date(data.value).toLocaleDateString('pt-BR', {timeZone: 'UTC'}) }} </b-badge>
              </template>

            </b-table>

          </transition>

          <transition v-if="isBusy" name="fade" mode="out-in">
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle mx-4"></b-spinner>
              <strong>Carregando ...</strong>
            </div>
          </transition>

        </div>
      </div>
      
    </div>
  <div>
    
</div>
<ModalNewUser :added.sync="guestAdded"/>
<ModalRemoveUser :userRemoving.sync="userRemoving" :removed.sync="removed"/>
<ModalEditUser :userEditing="userEditing" :edited.sync="edited" :closeModalStatus.sync="someModalClosed"/>
  </div>
</template>

<script>
import { api } from '@/services.js'
import { mapState } from "vuex";
import ModalNewUser from '@/components/admin/ModalNewUser.vue'
import ModalRemoveUser from '@/components/admin/ModalRemoveUser.vue'
import ModalEditUser from '@/components/admin/ModalEditUser.vue'

export default {
  name: "Dashbiard",
  components: {
    ModalRemoveUser,
    ModalNewUser,
    ModalEditUser
  },
  data() {
    return {
    json_fields: {
      "Id": "id",
      "Token": "token",
      "Nome": "name",
      "E-mail": "email",
      "Empresa": "company",
      "Status": {
        field: "presence",
        callback: (value) => {

          switch (value) {
            case "true":
              value = "Confirmado"
              break;
            case "false":
              value = "Não irá"
              break;
            case "standby":
              value = "Aguardando"
              break;
          
            default:
              break;
          }
          return value 
          
        },
      },
      "Data de envio do E-mail": {
        field: "sentemail",
        callback: (value) => {

          if(value != "false" || value != ""){
            return new Date(value).toLocaleDateString('pt-BR', {timeZone: 'UTC'})
          } else {
            return ''
          }
          
        },
      }
    },
      isBusy: false,        // CARREGAMENTO
      perPage: 30,           // PAGINAÇÃO
      currentPage: 1,
      keyword: '',          // FILTRO
      user: [],
      sortBy: 'timestamp',  // TABELA 
      errTxt: '',
      table: true,
      sortDesc: true,
      selectMode: 'multi',
      selected: [],
      fields: [
        {key: "token", label : 'Token'},
        {key: "name", label : 'Nome'},
        {key: "email", label : 'Email'},
        {key: "company", label : 'Empresa'},
        {key: "presence", label : 'Status'},
        {key: "sentemail", label : 'Envio'},
        {key: "selected", label : 'Send Email', class: 'text-center'}, // COLUN TABLE DE SELEÇÃO
        {key: "actions", label : 'Ações', class: 'text-center'}, // COLUN TABLE DE AÇÕES
      ],
      leads: [],
      btnAtualizar: 'ATUALIZAR',
      btnEnviar: 'Enviar',
      btnAddNovo: 'ADD NOVO',
      guestAdded: false,
      reumeStatus: {
        go: 0,
        loss: 0,
        standby: 0
      },
      userEditing: '',
      userRemoving: '',
      edited: false,
      removed: false,
      someModalClosed: false
    };
  },
  computed: {
    ...mapState(["usersStore"]),
    items() {
			return this.keyword
				? this.leads.filter(item =>  item.name.toLowerCase().includes(this.keyword.toLowerCase()) || item.email.toLowerCase().includes(this.keyword.toLowerCase()) || item.presence.toLowerCase().includes(this.keyword.toLowerCase())  )
				: this.leads
		},
    rows() {
      return this.leads.length
    },
    checkSelected() {
      return this.selected.length > 0 ? false : true
    },
    timestampModal(){
      let data = this.user.timestamp

      return data

       
    }
  },
  methods: {
     checked(index, checked) {
      if (checked === false){
        this.$refs.selectableTable.unselectRow(index)
      } else {
        this.$refs.selectableTable.selectRow(index)
      }
    },
    updateResumeStatus(data) {
      this.reumeStatus.go = data.filter(c=>c.presence=='true' || c.presence==true).length
      this.reumeStatus.loss = data.filter(c=>c.presence==false || c.presence == 'false').length
      this.reumeStatus.standby = data.filter(c=>c.presence == 'standby').length
    },
    getLeads() {
      api.get('/user/list').then(response => {
        this.updateResumeStatus(response.data)
        response.data.length > 0 ? this.leads = response.data : null
        this.$store.commit('UPDATE_USERSSTORE', response.data)
      })
    },
    async atualizar() {
      this.$store.commit('UPDATE_USERSSTORE', null)
      this.isBusy = true;
      this.table = false
      this.leads = []
      this.errTxt = ''
      // this.keyword = ''
      this.currentPage = 1
      this.btnAtualizar = 'ATUALIZANDO'
      await this.getLeads()

      setTimeout(() => {
        this.isBusy = false
        this.leads.length > 0 ? this.table = true : this.errTxt = 'Nenhum dado encontrato :( Tente novamente mais tarde.'
        this.btnAtualizar = 'ATUALIZAR'
      }, 500);
      
    },
    info(event){
      this.leads.filter(item => {
        item.id.toLowerCase().includes(event.toLowerCase()) ? this.user = item : null
      })
      this.$refs['my-modal'].show()
      // setTimeout(() => {
      //   this.clearSelected()
      // }, 10);
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
       console.log(this.$refs.selectableTable)
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    sendSelected() {
      this.btnEnviar = 'Enviando'
      
      api.post('/user/send-email', this.selected)
      .then(() => {
        this.btnEnviar = 'Enviar'
        this.clearSelected()
        this.atualizar()
      })
      // setTimeout(() => {
      //   this.btnEnviar = 'Enviar'
      //   this.selected
      //   this.clearSelected()
      // }, 1000);
      
    },
    addNovo() {
      this.$root.$emit('bv::show::modal', 'modalNewGuest')
    },
    removeGuest(tokenUser) {
      // console.log(tokenUser)
      this.userRemoving = tokenUser
      this.$root.$emit('bv::show::modal', 'modalRemoveGuest')
    },
    editGuest(tokenUser) {
      // console.log(tokenUser)
      this.userEditing = tokenUser
      this.$root.$emit('bv::show::modal', 'modalEditGuest')
    }
  },
  created() {
    this.atualizar()
  },
  watch:{
    guestAdded() {
      this.atualizar()
    },
    edited() {
      this.atualizar()
    },
    removed() {
      this.atualizar()
    },
    someModalClosed() {
      this.atualizar()
    }
  }
};
</script>

<style lang="scss">


$color: white;
.table {
  font-size: 13px;
  
}
td{
  text-align: center;
}
.xls{
  border-radius: 30px !important;
}
th>div{
  text-align: center;
}
input{
  max-width: 400px;
  background: $color;
  color: black;
  &::placeholder{
    color: black;
  }
  &:focus{
    box-shadow: none;
    background: $color;
  }
}
.box{
  background: $color;
  
}

.actionIcon {
  font-size: 1.25rem;
  transition: .2s;
  cursor: pointer;
  padding: 5px;
  &:hover {
    color: #FB2B67;
  }
}
</style>


