<template>
<div class="page pt-5">
  <Carregamento v-if=" presence == null" />
  <div v-else class="container">

    <div v-if="presence == 'true'">
      <div class=" d-flex justify-content-center">
        <!-- <img src="@/assets/img/img-yes.jpg" class="d-block mx-auto img-top" alt="img"> -->
        <iframe src=https://giphy.com/embed/S99cgkURVO62qemEKM width="480" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
      </div>
      <h1 class="text-center mt-4">
        YEAAAAH!
      </h1>
      <p class="text-center">
        Estamos muito felizes que você vai festejar com a gente!
      </p>
      <p class="text-center">
        Mas olha, deixa eu te contar, ano passado a gente fez tanta festança que os vizinhos reclamaram do barulho rsrs
        Então, vamos fazer a party desse ano no Pocket House mas em outra unidade (cuidado para não errar, tá?)
      </p>
      <p class="text-center">
        Local: Pocket House - Praça Panamericana<br>
        Av. Antônio Batuira, 470<br>
        Pertinho da Estação Cid. Universitária
      </p>
    </div>

    <div v-else>
      <div class="mt-5 d-flex justify-content-center">
        <!-- <img src="@/assets/img/img-no.jpg" class="d-block mx-auto img-top" alt="img"> -->
        <iframe src=https://giphy.com/embed/9Y5BbDSkSTiY8 width="480" height="336" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
      </div>
      <h1 class="text-center mt-4">
        AHHH! :(
      </h1>
      <p class="text-center">
        A gente vai sentir sua falta.<br/>
        Mas prometemos que vamos curtir por você também!

      </p>
    </div>

    <img src="@/assets/img/logo-festa.png" class="img-canta  d-block mx-auto" alt="Logo  Canta">

  </div>
</div>
</template>

<script>
import { api } from '@/services.js'
import { mapState } from "vuex";
import Carregamento from '@/components/Carregamento';

export default {
  name: 'Home',
  data() {
    return{
      presence: null
    }
  },
  components:{
    Carregamento
  },
  computed: {
      ...mapState(["user"]),
  },
  methods:{
    getUser(){
      api.get(`/user/store/${this.$route.params.token}`)
      .then(response => {
        console.log(response.data)
        if(response.data.code == 200){

          this.$store.commit('UPDATE_USER', response.data.data)
          let presence =  response.data.data.presence
          this.presence =  presence
          // if(presence != "standby"){
          //   this.$router.push(`/end/${this.$route.params.token}`)
          // }

        } else if (response.data.code == 401) {

          this.$router.push('/notfound')

        } else {

          this.$router.push('/notfound')

        }
        
      })
    }
  },
  created(){
    this.getUser()
  }
}
</script>

<style lang="scss" scoped>

@import url('../assets/scss/geral.scss');

$regular: "FreightSans Pro";
$bold: "FreightSans Pro Bold";
$light-italico: "FreightSans Pro Light Italico";
$regular-italico: "FreightSans Pro Italico";
$bold-italico: "FreightSans Pro Bold Italico";

$cor1: #FB2B67;
$cor2: #FAFAFA;
$cor3: #424242;
$cor4: #01D93B;


.img-top{
  height: 200px;
  @media(max-width: 767px){
   height: 180px;
  }
   @media(max-height: 570px){
     height: 140px;
    position: relative;
    top: -80px
  }
}

h1{
  color: $cor2;
  font-family: $bold-italico;
  font-size: 2.4rem;
  @media(max-height: 570px){
    font-size: 2rem;
    position: relative;
    top: -80px
  }
}

p{
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-family: $regular;
  color: $cor2;
  @media(max-height: 570px){
    font-size: 1.3rem;
    line-height: 1.7rem;
    position: relative;
    top: -80px
  }
}

.img-canta{
  height: 200px;
  @media(max-width: 767px){
   height: 200px;
  }
}

</style>
