
<template>
  <div>
    <p class="mb-3">
      <strong>Adicionar novo convidado</strong>
    </p>

    <b-alert show :variant="alert.type" v-if="alert.message">
      {{alert.message}}
    </b-alert>

    <b-form ref="form">
      <b-form-group
        id="formGroup-name"
        label="Nome"
      >
        <b-form-input
          id="input-name"
          type="text"
          placeholder="Nome"
          v-model="form.name"
          trim
          minlength="3"
          maxlength="255"
          required
        ></b-form-input>

        <b-form-invalid-feedback :state="validationName">
          Mínimo de 3 caracteres.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="formGroup-name"
        label="E-mail"
      >
        <b-form-input
          id="input-email"
          type="email"
          placeholder="E-mail"
          v-model="form.email"
          trim
          minlength="3"
          maxlength="255"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="formGroup-checkbox"
      >
        <b-form-checkbox
          id="input-checkbox"
          v-model="form.checkbox"
          name="checkbox"
          value="accepted"
          unchecked-value="not_accepted"
        >
          Confirmar convidado
        </b-form-checkbox>
      </b-form-group>

      <b-button class="btn btn-success" @click="submitForm" :disabled="button.disabled">
        {{button.text}}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(['guests']),

    validationName() {
      if(this.form.name === null) return;

      return this.form.name.length >= 3
    },
  },

  data(){
    return{
      data: null,

      alert: {
        message: null,
        type: 'danger',
      },

      form: {
        name: null,
        email: null,
        checkbox: 'not_accepted',
      },

      regexp: {
        // email: /^[a-zA-Z0-9\-\.\_]+[@][a-zA-Z0-9\-\.\_]+$/ 
      },

      button: {
        text: 'Inserir',
        disabled: true,
      }
    }
  },

  watch: {
    form: {
      handler() {
        this.checkAllFields()
      },
      deep: true,
    }
  },

  mounted(){
  },
  
  methods: {
    checkAllFields(){
      let error = false;

      for (const key of Object.keys(this.form)) {
        if(
          !this.form[key] || this.form[key].length < 3
        ){
          error = true;
        }
      }

      this.button.disabled = error;
      return error;
    },

    checkIfUserAlreadyExists(){
      this.data = this.guests

      if(!this.data){
        this.message('Erro ao verificar o usuário!', 'danger')
        return;
      }

      this.data = this.data.filter((e) => {
        return e.email == this.form.email
      })

      return this.data.length > 0;
    },

    async submitForm(){
      this.button.text = "Carregando..."
      this.button.disabled = true

      let result = this.checkAllFields()

      if(result){
        this.button.text = "Inserir"
        return
      }

      result = this.checkIfUserAlreadyExists()

      if(result){
        this.button.text = "Inserir"
        this.button.disabled = true
        this.message('Esse convidado já está na lista!', 'danger')
        return;
      }
      
      let data = {
        name: this.form.name,
        email: this.form.email,
        presence: this.form.checkbox == 'accepted' ? 1 : 0
      }

      try {
        await axios.post('https://apirsvp.cantacom.com.br/portaria/user/cadastro', data)
          .then((response) => {
            if(response.data.response === 'Created'){
              data['id'] = response.data.data.id
              
              this.data = JSON.parse(JSON.stringify(this.guests))
              this.data.push(data)
              
              this.data.sort(function(a, b) {
                return a.name.localeCompare(b.name);
              });

              this.$store.commit("SET_GUESTS", this.data)
              this.$store.commit("SET_SEARCH", this.data)

              // this.message('Convidado adicionado com sucesso!', 'success')
              this.resetForm()

              // Change tab
              document.querySelector(".nav-item a").click()
            }
            else if(response.data.email){
              this.message('Esse convidado já está na lista!', 'danger')
            }
          })
          .catch((error) => {
            console.log(error)

            this.button.disabled = false

            this.message('Erro ao adicionar o novo convidado. Tente novamente mais tarde', 'danger')
          })
      } catch (error) {
        console.log(error)

        this.button.disabled = false

        this.message('Erro ao adicionar o novo convidado. Tente novamente mais tarde', 'danger')
      }
    },

    message(message, typeMessage){
      // Message
      this.alert.message = message
      this.alert.type = typeMessage
      
      setTimeout(() => {
        this.alert.message = null
      }, 5000);
    },

    resetForm(){
      this.$refs.form.reset()
      this.button.text = "Inserir"
      this.button.disabled = true;

      this.form.name = null
      this.form.email = null
      this.form.checkbox = 'not_accepted'
    }
  },
}
</script>

<style lang="scss" scoped>
</style>