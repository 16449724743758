import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginDash: false,
    user: null,
    usersStore: null,
    guests: null,
    search: null,
  },
  mutations: {
    UPDATE_USER(state, payload){
      state.user = payload
    },
    UPDATE_LOGIN_DASH(state, payload){
      state.loginDash = payload
    },
    UPDATE_USERSSTORE(state, payload){
      state.usersStore = payload
    },
    SET_GUESTS(state, payload)
    {
      state.guests = payload;
    },
    SET_SEARCH(state, payload)
    {
      state.search = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
