<template>
  <b-modal id="modalRemoveGuest" no-close-on-backdrop no-close-on-esc hide-header hide-footer>
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-between align-items-center">
                <p class="modalTitle mb-0">Remover convidado</p>
                <i class="fas fa-times iconClose" @click.prevent="closeModal"></i>
            </div>
            <form @submit.prevent="removeGuest" class="formNewGuest mt-4">
                <b-alert :variant="someError.variant" show v-if="someError.status">
                    <i class="fas fa-info-circle mr-3"></i>{{someError.msg}}
                </b-alert>
                <p class="textModal">
                    Deseja mesmo remover o convidado <b>{{userRemoving.name}}</b>
                </p>                
                <div class="d-flex justify-content-end mt-3">
                    <button :disabled="isRemoving" @click.prevent="closeModal" class="btn btn-secondary mr-3">Voltar</button>
                    <button :disabled="isRemoving || removedStatus" class="btn btn-danger"><i class="fas fa-trash-alt mr-2"></i> Remover</button>
                </div>
            </form>
        </div>
    </div>
  </b-modal>
</template>

<script>
import { api } from '@/services.js'
export default {
name: 'ModalRemoveUser',
props:['userRemoving','removed'],
data() {
    return {
        form: {
            name:'',
            email:''
        },
        isRemoving: false,
        someError: {
            status: false,
            msg: '',
            variant:''
        },
        removedStatus: false
    }
},
methods: {
    closeModal() {
        this.removedStatus = false
        this.someError.status = false
        this.$bvModal.hide('modalRemoveGuest')
    },
    async removeGuest() {
        this.isRemoving = true

        try {
            await api.delete(`/user/destroy/${this.userRemoving.id}`, this.userRemoving.id).then(res => {
                console.log(res.data)
                if(res.data.code == 200) {
                    this.someError.status = true
                    this.someError.msg = 'Convidado removido com sucesso!'
                    this.someError.variant = 'success'
                    this.isRemoving = false
                    this.removedStatus = true
                    this.$emit("update:removed",!this.removed)
                }else {
                    this.someError.status = true
                    this.someError.msg = 'Algo de errado aconteceu, tente novamente mais tarde!'
                    this.someError.variant = 'danger'
                    this.isRemoving = false
                }
            })
        } catch (error) {
            this.someError.status = true
            this.someError.msg = 'Algo de errado aconteceu, tente novamente mais tarde!'
            this.someError.variant = 'danger'
            this.isRemoving = false
        }

    }
}
}
</script>

<style lang="scss" scoped>
    .modalTitle {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .iconClose {
        cursor: pointer;
        font-size: 1.5rem;
        transition: .2s;
        &:hover {
            color: #FB2B67;
        }
    }

    .textModal {
        font-size: 1.25rem;
    }
</style>