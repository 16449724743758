<template>
  <div>
    <b-btn :class=" ('actBtn btn ' + this.class)" @click="checkType" :disabled="disabled">
      {{text}}
    </b-btn>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: 'actionButtonComponent',
  props: [
    'propsData',
    'propsKey',
    'propsId',
    'propsConfirm',
    'propsText',
    'propsType'
  ],

  computed: {
    ...mapState(['guests']),
  },

  data(){
    return{
      text: this.propsText,
      disabled: false,
      class: this.propsType === 'confirm' ? 'btn-success' : 'btn-danger',
      guest: null, 
    }
  },

  mounted(){
    if(this.propsConfirm === 'standby'){
      this.confirm = 0
    }
    else if(this.propsConfirm){
      if(this.propsConfirm == "true"){
        this.confirm = 0;
      }
      else if(this.propsConfirm == "confirmado"){
        this.confirm = 1
      }
    }

    this.changeVariables()
  },

  methods: {
    showModalRemove() {
      var c = confirm('Deja realmente realizar essa ação?')

      if(c)
        this.remove()
    },
    showModalUpdate() {
      var c = confirm('Deja realmente realizar essa ação?')

      if(c)
        this.changeStatus()
    },

    changeVariables(){
      if(this.propsType === 'confirm'){
        if(this.confirm == 1 || this.confirm == true){
          this.text = "Desconfirmar"
          this.class = 'btn-danger'
        }
        else{
          this.text = this.propsText
          this.class = 'btn-success'
        }
      }
      else{
        this.text = this.propsText
        this.class = 'btn-danger'
      }
    },

    checkType(){
      document.querySelector("#input-search").value = ''

      if(this.propsType === 'confirm')
        this.checkStatus()
      else if(this.propsType === 'delete')
        this.showModalRemove()
    },

    checkStatus(){
      if(this.confirm == 1 || this.confirm == true){
        this.showModalUpdate()
      }
      else{
        this.changeStatus()
      }
    },

    changeStatus(){
      this.confirm = this.confirm == 0 ? 1 : 0
      
      let data = JSON.parse(JSON.stringify(this.guests))
     
      data.forEach(element => {
        if(element.id == this.propsId){
          element.presence = this.confirm == 0 ? 'true' : 'confirmado'
        }
      });

      this.update(data)
    },

    async update(data){
      this.disabled = true
    
      const confirm = this.confirm == 0 ? 'true' : 'confirmado'

      try {
        await axios.put(`https://apirsvp.cantacom.com.br/portaria/user/confirm-presence/${this.propsId}`, {
          'presence': confirm
        })
          .then((response) => {
            console.log(response)

            this.$store.commit("SET_GUESTS", data)
            this.$store.commit("SET_SEARCH", data)
      
            this.changeVariables()

            this.disabled = false
          })
          .catch((error) => {
            console.log(error)

            this.disabled = false
            
            this.$emit('actionButtonError')
          })
      } catch (error) {
        console.log(error)

        this.disabled = false
        
        this.$emit('actionButtonError')
      }
    },
    
    async remove(){
      this.disabled = true

      let data = JSON.parse(JSON.stringify(this.guests))
      let index = data.findIndex(e => {return e.id == this.propsId})

      try {
        await axios.delete(`https://apirsvp.cantacom.com.br/user/destroy/${this.propsData.id}`)
          .then(() => {
            data.splice(index, 1)

            data.sort(function(a, b) {
              return a.name.localeCompare(b.name);
            });

            this.$store.commit("SET_GUESTS", data)
            this.$store.commit("SET_SEARCH", data)
      
            this.changeVariables()

            this.disabled = false
          })
          .catch((error) => {
            console.log(error)

            this.disabled = false
            
            this.$emit('actionButtonError')
          })
      } catch (error) {
        console.log(error)

        this.disabled = false
        
        this.$emit('actionButtonError')
      }
    }
  },
}
</script>

<style>

</style>