<template>
  <div>

    <Login/>

  </div>
</template>

<script>
import Login from '@/components/admin/Login.vue'

export default {
    name: "Dashboard",
    components:{
        Login
    },
    computed: {
    }

}
</script>

<style>

</style>