<template>
  <div class="main portaria">
    <div class="container">
      <b-card class="card" no-body>
        <b-tabs pills card>
          <b-tab title="Convidados" active>
            <c-search class="mb-4" />
  
            <c-list />
          </b-tab>
  
          <b-tab title="Novo convidado" @click="clearSearch()">
            <c-form />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import cForm from '../components/c-form.vue';
import cSearch from '../components/c-search.vue';
import cList from '../components/guestList/c-list.vue';

export default {
  components: { cForm, cSearch, cList },
  name: 'IndexPage',

  data(){
    return{
    }
  },

  methods: {
    clearSearch(){
      document.getElementById("input-search").value = ''
    }
  },
}
</script>

<style lang="scss" scoped>
#app, .main{
  background-color: #1a1a1a;
}
</style>
<style lang="scss">
.portaria{
  box-sizing: border-box;
  padding: 20px;

  .card{
    box-shadow: 
      0 1px 6px rgba(0, 0, 0, .5),
      0 2px 4px rgba(0, 0, 0, .3),
      0 2px 8px rgba(0, 0, 0, .2)
    ;
  }

  .btn{
    border: none;
    overflow: hidden;

    &:hover,
    &:focus{
      background-color: #2f2f2f !important;
    }
  }

  .tabs{
    .nav-item{
      margin-right: 10px;

      &:last-child{
        margin-right: 0;
      }

      .nav-link{
        color: #2f2f2f !important;
        font-weight: 700;
        
        &.active{
          background-color: #BFD630 !important;
        }
      
        &:hover{
          color: #fff !important;
          background-color: #2f2f2f !important;
        }
      }
    }
  }
}
</style>
