<template>
  <div class="row">
    <div class="col">
      <p class="mb-2">
        <strong>Pesquisa</strong>
      </p>

      <b-form>
        <b-form-group>
          <b-form-input
            type="search"
            id="input-search"
            class="w-100"
            v-model="valueSearch"
          >
          </b-form-input>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'searchComponent',

  computed: {
    ...mapState(['guests', 'search']),
  },

  data(){
    return{
      valueSearch: '',
    }
  },

  watch: {
    valueSearch: {
      handler(){
        if(this.valueSearch && this.valueSearch.length > 0)
          this.doSearch()
      },
      deep: true,
    }
  },

  mounted(){
  },

  methods: {
    doSearch(){
      let data = JSON.parse(JSON.stringify(this.guests));

      // data = data.filter((e) => {
      //   return e.name && e.name.toLowerCase().includes(this.valueSearch.toLowerCase()) || e.email && e.email.toLowerCase().includes(this.valueSearch.toLowerCase())
      // })
      
      data = data.filter((e) => {
        if(e.name && e.name.toLowerCase().search(this.valueSearch.toLowerCase()) != -1){
          return e.name;
        }
        else if(e.email && e.email.toLowerCase().search(this.valueSearch.toLowerCase()) != -1){
          return e.email
        }
      })

      this.$store.commit("SET_SEARCH", data.length > 0 ? data : 'Não foi encontrado nenhum resultado!')
    },
  },
}
</script>
<style scoped>
  #input-search{
    max-width: initial;
  }
</style>