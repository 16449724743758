import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Confirm from '../views/Confirm.vue'
import Recuse from '../views/Recuse.vue'
import End from '../views/End.vue'
import NotFound from '../views/NotFound.vue'
import Admin from '../views/Admin.vue'
import Dashboard from '../views/Dashboard.vue'
import Portaria from '../views/Portaria.vue'

Vue.use(VueRouter)

const routes = [

  { path: '/', redirect: '/notfound' },

  {
    path: '/home/:token',
    redirect: '/confirm/:token'
    // name: 'Home',
    // component: Home
  },
  {
    path: '/confirm/:token',
    name: 'Confirm',
    component: Confirm
  },
  {
    path: '/recuse/:token',
    name: 'Recuse',
    component: Recuse
  },
  {
    path: '/end/:token',
    name: 'End',
    component: End
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/portaria',
    name: 'Portaria',
    component: Portaria
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
